.its-scoring-row {
    padding-left: 16px;
    padding-right: 16px;
    &.its-scoring-row__nested {
        margin-left: 32px;
        margin-right: 32px;
        padding-left: 0;
        padding-right: 0;
    }

    .its-scoring--chips {
        md-chips {
            max-width: 100%;
        }
        md-chip {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

.its-scoring-searchable-select__no-margin {
    .ite-selectable-search-container > md-input-container:first-of-type {
        margin: 0;
    }
}
.its-scoring--input-item {
    .md-errors-spacer {
        display: none;
    }
}

.its-chips-no-shadow {
    .md-default-theme .md-chips,
    .md-chips {
        box-shadow: none;
    }
}

.its-scoring--tag-chip {
    background: rgb(224, 224, 224);
    color: rgb(66, 66, 66);
    border-radius: 16px;
    height: 32px;
    padding: 0 12px;
    margin: 8px 8px 0 0;
    md-icon {
        margin-bottom: 2px;
    }
}

.its-scoring--tag-chip-icon {
    cursor: pointer;
}

.its-scoring--weighted-tag {
    background: $primarySurveyColour;
    color: white;
    md-icon {
        color: white;
    }
}

.its-scoring--weight-empty-state {
    margin: 50px;

    .its-scoring--weight-empty-sub-title {
        margin: 12px 0 12px 6px;
    }

    .its-scoring--weight-empty-blurb {
        @extend .its-text__light;
        margin-left: 6px;
    }
}

.its-scoring--weight-text {
    font-size: 20px;
}

.its-scoring--weight-text-red {
    color: $DangerColour;
}

.its-scoring--weight-text-grey {
    @extend .its-text__light;
}
