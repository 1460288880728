.its-section--scale {
    .its-scale--response-text {
        padding-bottom: 8px;
        color: #888;
    }

    .its-scale--response-button {
        border-radius: 0;
        border: 1px solid #eee;
        margin: 2px;
        text-align: center;
        padding: 5px;
        min-width: 48px;

        &:disabled {
            background-color: rgba(178, 206, 57, 0.87);
            border: 0;
            color: #fff;
        }
    }

    .its-scale--response-label {
        margin: auto 8px;
        word-break: break-word;
    }

    .its-scale--response-condensed {
        background: blue;
    }

    .its-scale--checkbox {
        margin-top: 8px;
        margin-left: 2px;
    }

    @include media-breakpoint-down('md') {
        .its-scale--response-text {
            min-width: 170px;
            text-align: left !important;
            padding-left: 16px;
            padding-bottom: 0;
        }
        .its-scale--parent-container {
            flex-direction: column;
            margin-left: 30px;
        }
        .its-scale--container {
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }
        .its-scale--checkbox {
            margin-left: 32px;
            margin-top: 16px;
        }
    }
}
