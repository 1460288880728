.its-contained-layout--notification {
    background: $WarnColour;
    margin-bottom: 20px;
    border: 1px solid darken($WarnColour, 8%);

    p {
        color: white;
        margin: 4px 0;
    }

    md-icon {
        color: white;
        margin-left: 12px;
        margin-right: 12px;
    }
}

.its-contained-layout--notification__grey {
    background: #b0b0b0;

    border: 1px solid darken(#b0b0b0, 8%);

    p {
        color: inherit;
    }

    md-icon {
        color: inherit;
    }
}

.sub-title-bar {
    background: $basePrimaryColour;
}

.its-content {
    background: $White;
    width: 100%;
    margin: 32px;
}

.its-panel--survey-items-header {
    @extend .its-text__light;
    @extend .its-text__medium;
    margin-left: 25px;
}

.clickable {
    cursor: pointer;
}

.high-emphasis {
    color: rgba(0, 0, 0, 0.87);
}

.medium-emphasis {
    color: rgba(0, 0, 0, 0.6);
}

.disabled {
    color: rgba(0, 0, 0, 0.38);
}

//TODO do we consolidate its-sidemenu with its-panel? or leave them separate since one is specific for menus while the other deals with functionality

.its-details-panel {
    @include media-breakpoint-up(md) {
        max-width: 435px;
        width: 435px;
    }

    .its-panel--menu-item {
        border-bottom: 1px solid #d3d3d3;
        width: 100%;

        i:first-child {
            width: 20%;
            padding: 25px;
        }

        span {
            position: relative;
            top: 5px;
            width: 80%;
            padding: 25px;
        }

        i:last-child {
            width: 10%;
            padding: 25px;
        }
    }

    .its-panel--menu-item:hover {
        background: #f6f6f6;
        cursor: pointer;
    }

    .its-panel--title {
        text-decoration: none;

        &:first-of-type {
            padding: 18px 25px 15px;
        }
        padding: 15px 25px;
        background: #fff;
        color: rgb(33, 33, 33);
        min-height: 55px;

        &.active {
            background-color: #f0f0f0;
        }

        &.clickable {
            cursor: pointer;
        }

        md-icon {
            padding-right: 10px;
        }
    }

    .its-panel--title-clickable {
        cursor: pointer;
        //transition: all ease-in 0.1s;
        &:hover {
            background-color: #fafafa;
        }
    }

    .its-panel--content {
        background-color: white;
        padding: 0 35px;
    }
}

@media (max-width: 959px) {
    .its-sidemenu {
        .its-sidemenu-section-title {
            display: none;
        }
    }
}
