.its-select--response-type-help-text {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    margin: 15px 0 15px 5px;
}

.its-select--icon-select-button {
    display: flex;
    padding: 5px;
    border-radius: 2px;
    min-width: 50px;
    justify-content: center;
    &:hover {
        cursor: pointer;
        background: #f6f6f6;
    }
}

.its-select--selected-icon {
    font-size: 26px;
}

.its-select--default-selected-text {
    margin: 0 0 0 5px;
}

.its-select--default-selected-icon {
    margin: 8px 0;
    font-size: 26px;
}

.its-select--icon-select-arrow {
    margin: 0 0 0 5px;
    transform: translateY(5px);
}
