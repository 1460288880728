form {
    /* needed due to a bug in material select overflow (https://github.com/angular/material/issues/6312) */
    .md-select-value *:first-child {
        flex: 1 1 auto;
    }

    md-chips.ng-invalid-required {
        md-chips-wrap {
            box-shadow: rgb(255, 0, 0) 0 2px;
        }
    }
}
