.its-calendar-late-date {
    margin-top: 2em;
}

.its-calendar-button-enabled {
    background-color: rgb(78, 200, 239);
    color: white;
    font-weight: bold;
}
.its-calendar-button-base {
    padding: 0;
    margin: 1em 0.5em 0.5em 0.5em;
    min-width: 1%;
    height: 3em;
}

.its-calendar-restrictions .md-icon-button {
    border-radius: 5%;
}
.its-calendar-restrictions {
    margin-bottom: 1em;
    margin-top: 20px;
}

.its-calc-date-warnings {
    font-size: 12px;
    color: rgb(221, 44, 0);
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    padding-top: 30px;
}

.its-calendar-late-date {
    margin-top: 80px;
}

.its-calendar-select-interval {
    position: relative;
    top: 20px;
    height: 36px;
}
