.its-group {
    margin: 20px 20px 40px 10px;

    .its-item--label {
        color: lighten(black, 40%);
        font-size: 16px;

        margin: 5px;
    }

    .its-item--left-spacer {
        display: none;
    }

    md-input-container {
        margin: 0;

        input {
            margin-bottom: 0;
        }
    }
}

.its-group--add-question-button {
    padding: 5px 10px;
    margin: 20px 0;
}

.its-group--label-hint {
    font-size: 11px;
    line-height: 14px;
    color: grey;
}

.its-group--icon {
    &.its-group--collapse-icon {
        transform: scale(1.5);
    }

    &.its-group--rotate {
        transform: scale(1.5) rotate(-90deg);
    }
}
