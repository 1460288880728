.its-radio--container {
    margin: 5px 0 25px 0;
    .its-radio--radio-button {
        margin: 15px 0;
    }
}

.its-radio--default-disabled {
    .md-on {
        background: rgba(0, 0, 0, 0.38);
    }
}

.its-radio--radio-list-container {
    .its-btn--toggle {
        margin-left: 0;
        margin-right: 12px;
    }
}

.its-radio--other--icons-with-answers--horizontal {
    margin: 14px 0;
}
