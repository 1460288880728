.its-translations--image {
    max-height: 300px;
    margin-bottom: 30px;
    max-width: 100%;
}

.its-translations--dropdown {
    min-width: 200px;
    margin: 30px 15px 30px 0;
}

.its-translations--page {
    background: white;
    margin: 0 20px 20px;
}

.its-translations--button {
    max-width: 100px;
    margin: 0 15px 15px;
}

.its-translations--margin-top-25 {
    margin-top: 25px;
}

.its-translations--enabling-lang-dropdown {
    max-width: 45%;
}

.its-translations--english-source-labelling {
    margin-top: 34px;
}

.its-translations--enabling-lang-switch {
    white-space: normal;

    .md-container {
        min-width: 36px;
    }
}
.its-translations--enabling-lang-switch-help {
    margin-top: 0;
}

.its-smart-prompt-text-area {
    width: 100%;

    .md-resize-wrapper {
        width: 100%;

        textarea {
            width: 100% !important;
            overflow: auto;
        }
    }
}
