//TODO ADD STANDARD FOR TABBED MODALS

.its-modal.its-modal__tabbed {
    md-dialog-content {
        padding: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        margin-bottom: 12px;
        margin-top: 12px;

        md-tabs-wrapper {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }

        md-tab-content {
            padding: 20px 32px;
        }
    }
}

.its-modal {
    .its-icon-select--icons-container {
        > p {
            margin: 12px 0;
        }
    }

    .its-icon-select--icon-container {
        margin: 10px;
        width: 80px;
        height: auto;
        color: rgba(0, 0, 0, 0.57);

        p {
            margin-top: 12px;
        }

        &:hover p,
        &:hover {
            cursor: pointer;
            color: black;
        }
    }

    .its-icon-select--icon-label {
        margin: 10px 0 0;
        text-align: center;
        height: 32px;
    }

    .its-icon-select--selected-icon {
        color: rgb(78, 200, 239);

        &:hover {
            color: rgb(78, 200, 239);
        }
        &:hover p {
            color: rgb(78, 200, 239);
        }
        p {
            font-weight: 500;
            color: rgb(78, 200, 239);
        }
    }
}

.its-icon--outline {
    font-weight: 500 !important;
}
