.its-display-text-render,
.its-display-text {
    strong {
        font-weight: bold;
    }

    em {
        font-style: italic !important;
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }
}

.its-display-text-render {
    font-size: 18px;

    p {
        font-size: 18px;
    }
}

.its-display-text--padding {
    width: 35px;
}
