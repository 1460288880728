.its-contact-center-modal-content {
    padding: 8px;

    md-input-container {
        margin: 0;
    }

    .its-contact-center-modal-content--contact-lists-auto-complete {
        .md-chip-input-container {
            width: 100%;
        }
    }
}

.its-contact-center--date-filter {
    background-color: $PrimaryColour;
}

//EMPTY STATE
.its-contact-center--chart-empty-state {
    .its-contact-center--chart-empty-state--text-container {
        width: 420px;
        min-height: 80px;
        border-radius: 5px;
        padding: 20px;
        text-align: center;
        border: 2px dashed $PrimaryColour;
        background-color: rgba($PrimaryColour, 0.03);
    }
}

.its-contact--disabled {
    background: $LightGrey;
}

.its-contact-center--contact-list-chips .md-chip-input-container {
    min-width: 215px;
}

.fr-popup.fr-active {
    z-index: 100 !important;
}

.its-contact--layout {
    padding: 0 12px;
}

.its-contact--center--record-link {
    color: $PrimaryColour;
    cursor: pointer;
    padding-left: 5px;

    md-icon {
        @include md-icon-size(16px);
        color: $PrimaryColour;
        padding: 0;
    }
}

.its-contact--history-entry {
    margin-bottom: 20px;
}

.its-contact--timestamp {
    @extend .its-text--caption;
}

.its-contact--sentiment-positive {
    color: $PositiveColour;
}

.its-contact--sentiment-neutral {
    color: $WarnColour;
}

.its-contact--sentiment-negative {
    color: $DangerColour;
}

.its-contact--subscriptions-chart-container {
    background-color: $White;
    border-bottom: 1px solid $BorderColour;

    .its-contact--subscriptions-chart-container--chart {
        margin: 20px;
    }

    .its-contact--subscriptions-chart-container--chart-meta {
        margin: 20px;
        width: 350px;

        .its-contact--subscriptions-chart-container--chart-meta--stats {
            margin-top: 10px;

            .its-contact--subscriptions-chart-container--chart-meta--stats--current-month {
                .value {
                    @extend .its-text--display;
                    @extend .its-text__primary;
                    margin-top: 5px;
                }

                .text {
                    @extend .its-text__light;
                }

                .delta {
                    margin-left: -5px;

                    &.positive {
                        md-icon {
                            @include md-icon-size(15px);
                            color: $PositiveColour;
                            margin-bottom: 4px;
                            margin-right: 6px;
                        }
                        color: $PositiveColour;
                    }

                    &.negative {
                        md-icon {
                            color: $DangerColour;
                        }
                        color: $DangerColour;
                    }

                    .text {
                        @extend .its-text__light;
                    }
                }
            }
        }

        .its-contact--subscriptions-chart-container--chart-meta--legend {
            @extend .its-text--caption;
            padding-left: 8px;
            margin-left: -5px;
            margin-top: 18px;

            .its-contact--subscriptions-chart-container--chart-meta--legend--disc {
                height: 8px;
                width: 8px;
                border-radius: 5px;
                margin-right: 8px;
            }
        }
    }
}

.its-datalist--cell-subscribed {
    border-left: 3px solid rgb(78, 200, 239);
}

.its-datalist--cell-unsubscribed {
    border-left: 3px solid rgb(176, 176, 176);
    background: #f1f1f1;
}

.its-datalist--cell-undeliverable {
    border-left: 3px solid rgb(246, 191, 38);
}
