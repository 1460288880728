.its-logic--list-item-container {
    @extend .its-margin-topbot__half;
    padding: $Padding_half 0 $Padding_half $Padding;
    background-color: $LightGrey;
    border: 1px solid $BorderColour;
    border-radius: 4px;
    position: relative;

    @include media-breakpoint-down('xs') {
        border-bottom: 1px solid $borderColor;
        padding: $Padding;
    }
}

.its-logic--operator {
    @extend .its-margin-sides__half;
    text-align: center;
}

.its-logic--chip {
    @extend .its-chip;
    @extend .its-margin;
    text-align: center;
    white-space: normal;
    height: auto;
    min-height: 22px;
}

.its-logic--invalid {
    background: rgba($DangerColour, 0.05);
    border-color: $DangerColour;
}

.its-logic-row--column {
    @extend .its-margin-topbot__half;
}

.its-logic--buttons-container {
    min-width: 100px;

    @include media-breakpoint-down('xs') {
        position: absolute;
        top: 37px;
        right: 0;
    }
}

.its-logic--margin-left-25 {
    margin-left: 25px;
}

.its-logic--highlight {
    background: #fafafa;
}

.its-logic--no-error-spacer {
    .md-errors-spacer {
        display: none;
    }
}

.its-logic--min-width-selection {
    min-width: 182px;
}

.its-logic--margin-left-50 {
    margin-left: 50px;
}

.its-logic--input-margin {
    margin-left: 25px;
}

.its-logic-location-warning {
    color: red;
    text-align: center;
}

.its-logic--condition-margin {
    margin: 0 15px;
}

.its-logic--cancel {
    min-width: 110px;
}

@media (max-width: 768px) {
    .its-logic--content {
        .its-logic--list-item-container {
            margin-left: 0px;
        }
    }
}
