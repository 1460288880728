.its-item-move--content {
    margin: 12px;
}

.its-item-move--checkbox {
    margin-bottom: 25px;
    margin-left: 2px !important;
}

.its-item-move--input {
    margin: 0 0 25px 0;
}
