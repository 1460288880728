.its-item--label {
    margin: 20px;
}

.its-item-list {
    padding: 0;
    margin: $Margin_half;
    border: $Border;
    border-radius: $BorderRadius;

    list-style-type: none;
    background: $LightGrey;
}

.its-item--edit-overlay {
    position: fixed;
    background: black;
    top: 109px;
    opacity: 0.15;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.its-dashed-border {
    border-bottom: $BorderColour 2px dashed;
}

.its-item--settings-container {
    margin-bottom: 35px;
}

.its-item--advanced-settings-container {
    margin-left: 20px;
    margin-top: 20px;
}

.its-item--advanced-settings-header {
    margin-bottom: 10px;
}

.its-item--empty-spacing {
    height: 50px;
}

.its-item {
    min-height: 125px;
}

.its-hint {
    color: grey;
    font-size: 12px;
}

.its-item--help-text {
    @extend .its-text--caption;
    @extend .its-margin-bot;
    display: block;
}
