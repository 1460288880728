.its-settings--container {
    @extend .margin-all;
    @include media-breakpoint-down('xs') {
        margin: 0;
    }
}

.its-settings-form {
    margin: 0;
}
.its-settings-form-box {
    padding: 10px;
    background: white;

    md-switch {
        margin-left: 0;
        .md-container {
            min-width: 36px;
        }
        .md-label {
            white-space: normal;
            line-height: initial;
        }
    }
}
.its-settings-menu {
    background: white;
    z-index: 1;

    .its-settings-menu--item {
        &.its-settings-menu--item__selected {
            background: #dadada;
        }
    }
}
.its-form-edit {
    .its-settings--hide-label-text {
        margin: 16px 0 16px 8px;
    }
}

.its-settings--add-another {
    margin-left: 32px;
    color: $primarySurveyColour;
    font-weight: 500;
    cursor: pointer;
}

.its-settings--reorder-button {
    cursor: move;
}

.its-settings-form-name input {
    width: 450px;
}

.its-settings-form p {
    margin-top: 0;
}

.its-settings--error-text {
    font-size: 11px;
    line-height: 14px;
    color: $DangerColour;
}

.its-settings--seconds-input {
    text-align: right;
}

.its-settings--seconds-text {
    margin: 0 0 24px 6px;
}
