.its-section--image-upload {
    .its-image-upload--add-image-container {
        border: dashed #eee 2px;
        height: 150px;
        border-radius: 5px;
        background-size: contain;
        margin-bottom: 10px;
        padding: 0 5px;

        h3 {
            color: #cccccc;
        }
    }
}
