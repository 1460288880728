.its-error {
    padding: 8px 0;
    .its-error-text {
        @extend .its-text--caption;
        @extend .its-text__danger;
    }
}
.its-bg-light-grey {
    background: #fafafa;
}
.its-content {
    background: $White;
    width: 100%;
    margin: 32px;
}
.its-small-icon {
    @include md-icon-size(20px);
}
.its-ng-message {
    color: rgb(221, 44, 0);
    display: flex;
    justify-content: center;
}
.its-nav-back {
    color: rgba(0, 0, 0, 0.87);

    &:hover {
        background: #f6f6f6;
    }
    &:active {
        background: #eee;
    }
    .md-button._md-nav-button.md-unselected {
        color: rgba(0, 0, 0, 0.87);
        .material-icons {
            color: rgba(0, 0, 0, 0.87);
        }
    }
}

.its-check-left-nav {
    position: fixed;
    top: 48px;
    width: 320px;
    overflow: scroll;
}

.its-button {
    .material-icons {
        font-size: 20px;
        height: 20px;
        width: 20px;
        padding-top: 2px;
        padding-right: 4px;
    }
}

.its-check-content-area {
    margin-left: 320px;
    padding: 20px;
}

md-whiteframe {
    background: white;
}

.its-froala .fr-toolbar.fr-top,
.its-froala .fr-toolbar,
.its-froala .fr-second-toolbar {
    border-radius: 0;
}

.fr-toolbar.fr-top {
    border-radius: none;
    border: 1px solid #eee;
}

.fr-box.fr-basic.fr-top .fr-wrapper {
    box-shadow: none;
    border: 1px solid #eee;
    border-top: 0;
}

.its-section-card {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    padding: 100px;
    margin: 20px;
}

.its-section-card--divider {
    margin-bottom: 10px;
    border-top-color: #f1f1f1;
}

.its-section-card--title {
    padding: 20px;
}

.its-scoring-page--empty-state {
    padding-top: 20px;
    padding-left: 20px;
}

.its-nav-footer {
    border-top: 1px solid #ddd;
    height: 40px;
    background: white;
    padding-right: 10px;
    padding-left: 10px;
}
