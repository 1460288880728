.its-form-edit {
    .its-form-edit--content {
        margin: 18px;
        background-color: white;
    }

    .its-form-edit--top-padding {
        padding-top: 14px;
    }

    .item-setting-icon {
        color: #aaa;
        font-size: 20px;

        &:hover {
            color: rgba(0, 0, 0, 0.87);
        }
    }

    .item-span-icon {
        md-icon {
            color: $primarySurveyColour;
        }

        &:hover {
            cursor: default;
        }
    }

    .its-form-edit-groups {
        md-list-item {
            padding: 0;
        }
        .its-form-edit-groups--btn {
            box-shadow: none;
            background-color: #fff;
        }
    }
    .its-form-edit--sidenav {
        z-index: 1;
    }
    .its-form-edit-groups {
        padding: 0;

        .its-form-edit-groups--section {
            .its-form-edit-groups--section--btn {
                box-shadow: none;
                background: #fff;
                margin: 5px;
                padding: 10px 15px;
            }
            .its-form-edit-groups--section--btn:hover {
                background: #eee;
                cursor: move;
            }
            .its-form-edit-groups--section--icon {
                padding-right: 20px;
            }
        }
        .its-form-edit-groups__selected {
            background: #fff;
        }
    }
}

.its-icon-button {
    md-icon {
        margin: 0;
    }
    .its-icon-button--text {
        font-size: 10px;
        line-height: 10px; // due to .md-button line-height from material
        height: 16px;
    }
}

.its-condensed-switch {
    margin: 8px 0;
}

.its-form--label {
    padding: 16px;
}

.its-form--create-another {
    margin: 0 0 0 16px !important;
}

.its-form--label-editing-container {
    margin: 7px 6px;
    padding-top: 0;
    padding-left: 6px;
    width: 95%;

    /* hide error spacer */
    .md-error-spacer {
        display: none;
    }

    .its-form--label-editing {
        padding: 0;
        margin: 0;
    }
}
.its-logic-menu {
    .its-logic-menu--label {
        max-width: 200px;
    }
}

.its-form-edit--menu-item {
    @extend .its-padding;
    @extend .its-hover !optional;
    @extend .its-draggable;

    .its-form-edit--menu-item--label {
        padding-left: 25px;
    }
}

.its-form--edit-label-container {
    &.its-form-edit--hover {
        background: #fafafa;
    }

    .its-form--section-label {
        min-height: 24px;
        margin: 0;
    }

    .its-form--edit-label-icon {
        @include md-icon-size(18px);
        margin-left: 6px;
    }
}

.its-form-edit--question-toolbar {
    max-width: 550px;
}

.its-form--border-2 {
    border-radius: 2px;
}

.its-form--border-top-2 {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.its-form-edit--toolbar {
    background: #fff;
}

.its-form--add-section-button-top {
    padding: 5px 10px;
    margin: 20px 0 0;
}

.its-form--add-section-button-bot {
    padding: 5px 10px;
    margin: 0 0 20px;
}

.its-form-edit--custom-question-container {
    margin: 0;
    padding: 0 5px;
}

.its-form-group {
}

.its-form-section__editing {
    background: #f9f9f9;
    margin-left: 0 !important;
    padding-left: 40px;
}

.its-form-section__editing_group {
    background: #f9f9f9;
    margin-left: 0 !important;
    box-shadow: 0 0 7px #aaa;
    padding-left: 40px;
}

.its-topbar-tabs {
    z-index: 3;
    background: #fff;
}

.its-item {
    margin-left: 40px;
    //border-left: 1px solid #eee;
    border-top: 0;
    border-right: 0;

    .its-item--right-spacer {
        margin-right: 40px;
        padding-right: 16px;
    }
}

.its-form-section__editing .its-form--edit-label-container {
    background-color: #f1f0f0;
}

.its-form-section__editing .its-item-response {
    min-width: 150px;
    margin-right: 0;
}

.its-item-response {
    min-height: 20px;
}

.its-no-padding-list {
    padding: 0;
    .md-list-item-inner {
        padding: 0;
    }
}
.its-item--states {
    border-top: 1px dashed #eee;
    padding: 5px;
    md-icon {
        font-size: 20px;
        width: 20px;
        height: 20px;
        color: #ccc;
    }
}

.its-reorder-icon {
    color: #aaa;
    cursor: pointer;
    &:hover {
        color: rgba(0, 0, 0, 0.87);
    }
}

.its-item-inline-settings,
.its-group-item-inline-settings {
    position: absolute;
    top: 0;
    bottom: 50px;
    left: 10px;
    width: 35px;
    background-color: #fff;
    padding: 5px;

    .its-add-icon {
        cursor: pointer;
        text-align: center;
        md-icon {
            color: #aaa;
            background: #fff;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;
            &:hover {
                background: #fff;
            }
        }
    }
}

.its-form--settings-button {
    box-shadow: none;
    background-color: transparent;
    color: rgb(33, 33, 33);
}

.its-group-item-inline-settings {
    left: -10px;
}

.dndPlaceholder {
    @extend .its-margin;
    border: 2px dashed $BorderColour;
    background-color: #f9f9f9;
    opacity: 0.5;
    height: 130px;
}
.its-dnd-list__compact {
    list-style-type: none;
    .dndPlaceholder {
        margin: 0;
        height: 36px;
    }
    .dndDraggingSource {
        display: none;
    }
}

.its-editor-list--section-divider {
    border-bottom: 2px dashed #eee;
}

.its-section--empty .dndPlaceholder {
    border: 0;
    height: 0;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.its-section--empty {
    font-size: 1.2em;
    color: #888;
    text-align: center;
    background: #f9f9f9;
    padding: 70px 20px;
    border: 2px dashed #ddd;
    margin: $Margin;

    .its-section--empty--icon {
        @include md-icon-size(38px);
    }
}

.its-group--empty {
    &.dndDragover {
        background: rgba($PrimaryColour, 0.1);
    }
}

.its-section--new-empty-survey {
    margin: 10px;
    padding: 5% 15%;
    border: 2px dashed $BorderColour;
    border-radius: 4px;
    background-color: $LightGrey;

    .dndPlaceholder {
        border: 0;
        height: 0;
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
}

.its-smart-prompt--textarea {
    resize: none;
    overflow: auto;
    border: none;
    margin-top: 4px;
    padding: 4px;
}

.its-smart-prompt--input-container {
    padding: 0 8px;
    margin-left: -8px;

    md-input-container .md-input:invalid {
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    }
}

.its-smart-prompt--add-button {
    color: rgb(78, 200, 239);
    margin-left: 0;
    margin-bottom: 10px;

    md-icon {
        color: rgb(78, 200, 239);
    }
}
