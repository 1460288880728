.its-page-icon {
    font-size: 72px;
    height: auto;
    width: auto;
    margin-left: 0;
    margin-right: 0;
}

.bg-white {
    background: white;
}

.its-square-button {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

.md-button.md-icon-button {
    &.no-margin {
        @extend .no-margin;
    }
}

.switch-indent {
    margin-left: 44px;
}

.transparent-bg {
    background-color: transparent;
}

.its-layout-wrap {
    flex-wrap: wrap;
}
