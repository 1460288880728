#previewFrameContent {
    width: 100%;
    height: 100%;
}

#previewFrame {
    #previewFrameContent {
        border: none;
    }
    .desktop {
        //width: 1024px;
        height: 100%;
        width: 100%;

        //border-top: 35px solid #EEE;
        //border-bottom: 60px solid #EEE;
        //border-left: 15px solid #EEE;
        //border-right: 15px solid #EEE;
        //border-radius: 15px;
    }

    .tablet {
        width: 768px;
        height: 1024px;

        border-top: 35px solid #eee;
        border-bottom: 60px solid #eee;
        border-left: 15px solid #eee;
        border-right: 15px solid #eee;
        border-radius: 15px;
    }

    .phone {
        width: 375px;
        height: 667px;

        border-top: 35px solid #eee;
        border-bottom: 60px solid #eee;
        border-left: 15px solid #eee;
        border-right: 15px solid #eee;
        border-radius: 15px;
    }
}

.its-preview-device--phone {
    position: absolute;
    top: 88px;
    bottom: 88px;
    right: 20px;
    left: 20px;

    &.its-preview-device--phone__landscape {
        top: 223px;
        bottom: 223px;
        right: -107px;
        left: -110px;
    }
}

.its-preview--panel-content {
    padding: 0px 31px 35px 65px;
    background-color: #fff;

    @include media-breakpoint-down('xs') {
        padding: 0 10px 10px;

        md-switch {
            height: unset;
            white-space: normal;
            margin-right: 0;
            line-height: unset;

            .md-container {
                min-width: 36px;
            }
        }
    }
}

.its-preview--setting-title {
    padding-top: 20px;
    padding-bottom: 20px;
}

.its-preview--setting-value {
    margin-top: 5px;
}

.its-preview-device-btn.md-button[disabled] {
    background: lightgrey;
}

.its-preview-device-btn {
    padding: 20px;

    &.its-preview-device-btn--selected {
        background: lightgrey;
    }
}

.its-preview-device-frame--tablet {
    width: 500px;

    &.its-preview-device-frame--tablet__landscape {
        transform: rotate(90deg);
    }
}

.its-preview-reset {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

.its-preview-device-frame--phone {
    width: 375px;

    &.its-preview-device-frame--phone__landscape {
        transform: rotate(90deg);
    }
}

.its-preview-device--tablet {
    position: absolute;
    top: -94px;
    bottom: -94px;
    right: -85px;
    left: -85px;
    transform: scale(0.65);

    &.its-preview-device--tablet__landscape {
        transform: scale(0.48);
        top: -102px;
        bottom: -102px;
        right: -354px;
        left: -354px;
    }
}

.its-preview-container--phone,
.its-preview-container--tablet {
    margin-left: auto;
    margin-right: auto;
    padding: 18px;
}

.its-md-color-picker {
    margin-top: -20px;

    .md-color-picker-input-container {
        padding-bottom: 30px;
    }

    .md-color-picker-preview {
        width: 30px !important;
        height: 30px !important;
        padding: 2px;
        box-shadow: none !important;
        background: #fff !important;
        background-image: none;
        border: 1px solid #ccc !important;

        .md-color-picker-result {
            border-radius: 50%;
            background-image: linear-gradient(45deg, #eee 25%, transparent 25%),
                linear-gradient(-45deg, #eee 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #eee 75%),
                linear-gradient(-45deg, transparent 75%, #eee 75%);
            background-size: 10px 10px;
            background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
        }
    }
    md-input-container {
        display: none;
    }
}

@media (max-width: 1380px) {
    .its-preview-device__landscape-container {
        transform: scale(0.85);
    }
}

@media (max-width: 1250px) {
    .its-preview-device__landscape-container {
        transform: scale(0.75);
    }
}

@media (max-width: 1180px) {
    .its-preview-device__landscape-container {
        transform: scale(0.6);
    }
}
