.its-custom-js-text-area {
    .md-resize-wrapper {
        height: 100%;

        textarea {
            height: 100% !important;
            overflow: auto;
        }
    }
}
