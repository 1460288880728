.its-distributions--survey-link {
    margin-left: 0;
    padding-left: 0;
    word-wrap: break-word;
    word-break: break-all;
}

.its-distributions--action-button {
    min-width: 45px;

    & div {
        @extend .its-text--caption;
        text-transform: none;
        margin-top: -5px;
    }
}

.its-distributions--list-container,
.its-distributions--list-container__small {
    .its-distributions--list {
        margin: 14px;
        background: white;
        border-radius: 3px;

        .its-distributions--list-header {
            padding: 8px 0 0 20px;
        }
    }
}

.its-distributions--list-container__small.flex-gt-sm-50 {
    @include media-breakpoint-up('md') {
        min-height: 448px;
        max-height: 448px;

        .its-distributions--list {
            min-height: 434px;
            max-height: 434px;
        }
    }
}

.its-email-footer--text {
    @extend .its-text__muted;
}

.its-email-footer--logo {
    max-height: 32px;

    img {
        max-height: 32px;
    }
}

.its-distributions--auto-delivery {
    margin-left: 45px;
}

.its-distributions--email--hint {
    @extend .its-text--caption;
}

.its-distributions--description {
    @extend .its-text__muted;
}

.its-distributions--social-button {
    margin: 5px 2px;
    text-transform: none;
}

.its-distributions--sms-body {
    textarea {
        padding-right: 10px !important;
    }

    its-piped-text-selector {
        md-menu-bar {
            bottom: 5px;
        }
    }
}

.its-distributions--facebook {
    margin-left: 0;
    background-color: #3b5998;

    &:not([disabled]):hover {
        background-color: darken(#3b5998, 10%);
    }
}

.its-distributions--twitter {
    background-color: #00aced;

    &:not([disabled]):hover {
        background-color: darken(#00aced, 10%);
    }
}

.its-distributions--google {
    background-color: #d34836;

    &:not([disabled]):hover {
        background-color: darken(#d34836, 10%);
    }
}

.its-distributions--linked-in {
    background-color: #0077b5;

    &:not([disabled]):hover {
        background-color: darken(#0077b5, 10%);
    }
}

.its-distributions--pinterest {
    background-color: $DangerColour;

    &:not([disabled]):hover {
        background-color: darken($DangerColour, 10%);
    }
}

#survey-url::selection {
    color: rgb(78, 200, 239);
}

.its-health--healthy {
    color: $PositiveColour;
}

.its-health--average {
    color: $WarnColour;
}

.its-health--unhealthy {
    color: $DangerColour;
}

.its-health--section {
    padding: 24px 0;

    a {
        color: $PositiveColour;
    }
}

.its-health--footer-divider {
    margin: 0 -24px;
}

.its-health--footer {
    padding: 8px 0;
}

.its-distributions-reach-message {
    @extend .its-text__muted;
    margin-bottom: 10px;
}

.its-distributions-modal--date-picker {
    padding-right: 5px;
}

.its-distributions--time {
    padding-right: 5px;
    max-width: 98px;
}

.its-distributions--timezone {
    max-width: 160px;
}

.its-distributions-modal--date-invalid {
    color: $DangerColour;
    font-weight: bold;
    font-size: 85%;
    margin-top: -10px;
    text-align: center;
}

.its-scheduler--date-picker {
    md-datepicker {
        height: 34px;

        .md-datepicker-button {
            top: 0;
        }

        div.md-datepicker-input-container {
            input.md-datepicker-input {
                height: 34px;
            }

            button.md-datepicker-triangle-button {
                bottom: -1px;
            }
        }
    }
}

.its-scheduler--time.layout-align-center-center > * {
    box-sizing: inherit;
}
