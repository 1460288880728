.its-table-container {
    padding: 20px 20px 0;

    .its-table--pager-row {
        padding-bottom: 5px;
    }
}

.its-table-container-bottom-padding {
    @extend .its-table-container;
    padding-bottom: 80px;
}

.its-table-container-tabbed {
    @extend .its-table-container;

    padding: 85px 20px 0;
}

.its-table {
    margin-bottom: 20px;

    .its-table--header {
        span {
            padding: 3px 0 0 12px;
        }
    }

    .its-table--paged {
    }

    .its-table--paged-no-header {
        margin-top: 190px;
    }
}

@include media-breakpoint-down(lg) {
    .its-table--span-width {
        width: 90px;
    }
}
