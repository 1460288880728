.its-section {
    margin: $Margin_half;

    @include its-media-breakpoint-down('sm') {
        margin: $Margin_half;
    }
}

.its-section--add-question-button {
    padding: 50px 0;
    border: 2px dashed $BorderColour;
    margin: 0;
}

.its-section--icon {
    &.its-section--collapse-icon {
        transform: scale(1.5);
    }

    &.its-section--rotate {
        transform: scale(1.5) rotate(-90deg);
    }
}

.its-section-items > .md-list-item-inner {
    padding-right: 0;
}

.its-section-item {
    margin: $Margin_half;
    border-radius: $BorderRadius;
    border: $Border;
    background: $White;
    @extend .its-overflow--hidden;

    &.its-section-item__editing {
        @extend .its-editing-frame;
        position: relative;
        z-index: 2;
    }
}
.its-section__editing {
    @extend .its-editing-frame;
    position: relative;
    z-index: 2;
}

.its-editing-frame {
    border: 2px dashed $BorderColour;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
}

.its-no-dragging-source {
    .dndDraggingSource {
        display: none;
    }
}

.its-item-handle {
    cursor: move;
    margin: 0;
}

.its-item-handle-block {
    background: transparent;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.its-disabled-state {
    opacity: 0.25;
}
