// No more position fixed
#its-topbar {
    position: relative;
}

.its-toolbar {
    width: 100%;
}

// Reset these to use default material on <md-toolbar>
.its-toolbar--tabs {
    position: static;
}

// Now we're not using position fixed, we don't need some of the offset padding/margins
#its-content {
    padding-top: 0;
}

.md-button.md-default-theme.md-fab md-icon,
.md-button.md-fab md-icon {
    color: white;
}
.its-contained-layout--container {
    .its-contained-layout--item {
        @extend .md-whiteframe-1dp !optional;
        border-left: 3px solid $primarySurveyColour;

        &.its-contained-layout--item__disabled {
            border-left-color: #ddd;
            h2 {
                color: #ddd;
            }
        }
    }
}

.its-analytics {
    .its-custom-analytics--card {
        margin: 0;
    }
}

.its-custom-datatable {
    margin: 0;
    flex: 0 1 auto;
}

// Makes checkmarks white in checked checkboxes
md-checkbox.md-checked {
    .md-icon:after {
        border-color: white;
    }
}

#its-sidebar {
    &::-webkit-scrollbar-thumb {
        background: $primarySurveyColour85;
    }

    &:hover {
        &::-webkit-scrollbar-thumb {
            background: $primarySurveyColour;
        }
    }
}

// add this class to md-input-container holding an md-datepicker to create full-length
.date-picker {
    &._md-datepicker-floating-label {
        &._md-datepicker-has-calendar-icon {
            > label {
                &:not(.md-no-float) {
                    &:not(.md-container-ignore) {
                        left: 34px;
                        right: 0;
                    }
                }
            }
        }
    }

    md-datepicker {
        display: inline-block;
        padding: 0;
        width: 100%;

        .md-datepicker-button {
            margin-left: -12px;
        }

        .md-datepicker-input-container {
            display: flex;

            .md-datepicker-input {
                max-width: 100%;
                min-width: 100%;
            }

            .md-datepicker-triangle-button {
                right: 8px;
            }
        }
    }

    .md-input-messages-animation {
        .md-input-message-animation {
            margin-left: 34px;
        }
    }
}

.fr-popup {
    .fr-input-line {
        input + label {
            top: -5px !important;
        }
    }
}
