.its-icon-response--icon {
    padding: 5px 0;
    margin: 5px 0;
    width: 32px;
    display: flex !important;
    justify-content: center !important;
    opacity: 0.54;
}

.its-icon-response--icon-selected {
    color: #ccc;
    .its-icon-response--icon {
        opacity: 1;
        border-bottom: 3px #ccc solid;
    }
}

.its-icon-response--text {
    padding-left: 10px;
    padding-right: 10px;
    color: rgba(0, 0, 0, 0.38);
}
