.its-nps--response-button {
    min-width: 50px;
    max-width: 50px;
    border-radius: 0;
    border: 1px solid #eee;
    margin: 2px;
    text-align: center;
    padding: 5px;
    overflow: visible;
}
.its-nps--text-heading {
    padding-top: 14px;
    padding-left: 2px;
    padding-bottom: 8px;
    margin-bottom: 18px;
}
.its-nps--response-button:disabled {
    background-color: rgba(178, 206, 57, 0.87);
    border: 0;
    color: #fff;
}

.its-nps--icon-group-text {
    margin: 0 0 0 10px;
}

.its-nps--icon-group-margin {
    margin-top: 12px;
}

.its-nps--detractor-icon {
    position: absolute;
    transform: translate3d(-15px, 48px, 0);
}

.its-nps--promoter-icon {
    position: absolute;
    transform: translate3d(-19px, 48px, 0);
}

.its-nps--response-label {
    margin: 20px;
}

.its-nps--range-value {
    margin-bottom: 25px;
}

.its-nps-labels {
    color: grey;
    font-size: 14px;
}
