.its-toolbar--responsive {
    position: fixed;
    width: calc(100% - 64px);
    top: 48px;
}

@media (max-width: 960px) {
    .its-toolbar--responsive {
        width: 100%;
    }
}
