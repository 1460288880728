@media print {
    * {
        overflow: visible !important;
        -webkit-transition: none !important;
        transition: none !important;
        position: relative;
    }
    its-side-nav-bar {
        display: none !important;
    }
    .its-sidebar--animation--spawn,
    .its-sidebar--menu-item-list,
    its-top-nav-bar,
    .its-topbar-tabs,
    .its-panel--left,
    .its-icon-menu,
    its-add-new-section,
    .its-section-item-actions,
    its-collapse-arrow,
    .its-section-actions,
    its-item-extra-information,
    .its-survey-add-question-bar,
    .its-item--states,
    its-drag-and-drop-handle {
        display: none !important;
    }
    .its-chip {
        max-width: unset;
        .its-chip--text {
            max-width: unset;
        }
    }
    .its-item-list {
        margin: 0;
        background: transparent;
        border: none;
    }
    .its-section-item,
    its-checkbox-render,
    its-checkbox-render .its-margin-sides {
        page-break-inside: avoid;
        display: block;
    }
    .its-section-item {
    }
    img {
        page-break-before: auto;
        page-break-after: auto;
        page-break-inside: avoid;
    }
    .its-section-render-container {
        page-break-after: always;
    }
    .its-response-image {
        page-break-inside: avoid;
        display: block;
    }
    .its-nps-container {
        display: block;
    }
}
