.its-slider {
    &[md-discrete] {
        .md-sign,
        .md-sign:after {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1);
        }

        .md-thumb {
            transform: scale(0);
        }

        .md-track-container {
            height: 6px;

            .md-track {
                -webkit-border-radius: 6px;
                -moz-border-radius: 6px;
                border-radius: 6px;
            }
        }

        .md-sign {
            top: 12px;
            cursor: col-resize;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
            .md-thumb-text {
                color: white;
            }

            &:after {
                opacity: 0 !important;
            }
        }

        &.md-dragging {
            .md-sign {
                top: -16px;

                .md-thumb-text {
                    color: white;
                }

                &:after {
                    opacity: 1 !important;
                }
            }
        }
    }
}

.its-slider--hide-numbers {
    .md-thumb-text {
        display: none;
    }
}
