.its-section--ranking {
    margin-bottom: 10px;

    .its-ranking--order-item {
        min-height: 32px;
        margin: 10px 0;

        md-icon {
            margin-right: 5px;
            color: #aaaaaa;
        }

        .its-ranking--order-item-label {
            background: #e0e0e0;
            cursor: move;
            border-radius: 16px;
            display: block;
            height: 32px;
            line-height: 32px;
            padding: 0 12px;
            box-sizing: border-box;
            max-width: 100%;

            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
        }
    }
}

.its-ranking--response-list {
    padding-left: 0;

    .dndDraggingSource {
        display: none;
    }

    .dndPlaceholder {
        height: 75px !important;
        background: rgba($primarySurveyColour, 0.15);
        border-top: solid $primarySurveyColour 1px;
        border-bottom: solid $primarySurveyColour 1px;
    }
}

.its-ranking--order-index {
    border-radius: 4px;
    background: $LightGrey;
    border: 1px solid $BorderColour;
    margin: auto $Margin_half auto $Margin;
    width: 24px;
    height: 24px;
    padding: 0;
    text-align: center;
    line-height: 24px;
}

.its-ranking--order-index-settings-spacer {
    margin: 8px 8px 0 0;
}

.its-ranking--order-index-section-height {
    line-height: 32px;
}
