.its-checkbox--upload-photo-icon {
    margin: -26px 0 0 -10px;
    padding: 0;
    min-width: 40px;
    width: 40px;
    min-height: 40px;
    height: 40px;

    md-icon {
        color: rgba(0, 0, 0, 0.85);
    }
}

.its-checkbox--upload-photo-icon-success {
    md-icon {
        color: rgb(78, 200, 239);
    }
}

.its-checkbox--upload-photo-icon-error {
    md-icon {
        color: #dd2c00;
    }
}

.its-checkbox--response-title {
    margin-top: 30px;
    margin-bottom: 20px;
}

.its-checkbox--response-list {
    padding: 0;
    margin: 0;

    .dndPlaceholder {
        height: 75px !important;
        background: rgba($primarySurveyColour, 0.15);
        border-top: solid $primarySurveyColour 1px;
        border-bottom: solid $primarySurveyColour 1px;
    }
}

.its-checkbox--error-text {
    font-weight: 500;
    color: #dd2c00;
    font-size: 12px;
    margin-bottom: 12px;
}

.its-checkbox--image-response {
    max-height: 250px;
    max-width: 95%;
    position: relative;
    width: auto;
}

.its-checkbox--default-image-response-outter {
    position: absolute;
    left: calc(50% - 28px);
    top: calc(50% - 28px);
    height: 56px;
    width: 56px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.85);

    .its-checkbox--default-image-response-inner {
        position: absolute;
        left: calc(50% - 12px);
        top: calc(50% - 12px);
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.85);

        md-icon {
            color: rgba(0, 0, 0, 0.85);
        }
    }
}

.its-checkbox--image-container {
    position: relative;
    display: block;
    width: fit-content;
}

.its-checkbox--other--answers-only {
    .md-container {
        top: 50%;
    }
}

.its-checkbox--checkbox-list-container {
    .its-btn--toggle {
        margin-left: 0;
        margin-right: 12px;
    }
}
