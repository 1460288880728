.its-section--audio {
    color: #cccccc;
}

.its-audio--disclaimer-text {
    left: 2px;
    right: auto;
    bottom: 7px;
    font-size: 0.75em;
    line-height: 14px;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    color: grey;
}

.its-audio--record-button {
    margin: 10px;

    .its-audio--record-button-icon {
        font-size: 30px;
        margin-left: 6px;
    }
}

.md-button.md-fab[disabled].its-audio--record-button .its-audio--record-button-icon {
    color: #ffffff;
}
