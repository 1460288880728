.its-video--container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

.its-video--video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
